import axios from 'axios';

export default {
  getShortCircuitFormsWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/ShortCircuitForms', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  addShortCircuitForm: (shortCircuitForm) => axios.post('/api/ShortCircuitForms', shortCircuitForm),
  updateShortCircuitForm: (shortCircuitForm) => axios.put(`/api/ShortCircuitForms/${shortCircuitForm.id}`, shortCircuitForm),
  deleteShortCircuitForm: (id) => axios.delete(`/api/ShortCircuitForms/${id}`),
};
